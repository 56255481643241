.portfolio {
    .wp-block-image {
        border-radius: 1rem;
        overflow: hidden;
        box-shadow: 0 0 12px rgba(0, 0, 0, .4);
    }
}

.wp-block-gallery {
    --gallery-block--gutter-size: 32px;
}

.wp-block-gallery.has-nested-images {
    justify-content: center;
    
    figure.wp-block-image {
        flex-grow: unset;
    }
}