#main {
    .hero-section[data-type='type-2'] {
        padding-top: unset;
    }
}

@include break-below(sm) {
    .hero-content {
        min-height: 460px !important;
    }
}