.home {
    &__hero {
        @include break-below(sm) {
            min-height: 460px !important;
        }

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 125px;
            bottom: 0;
            left: 0;
            background-image: url(../images/shape-bottom.svg);
            background-repeat: no-repeat;
            background-size: 100% 125px;
            z-index: 2;

            @include break-below(sm) {
                height: 64px;
                background-size: 100% 64px;
            }
        }

        .wp-block-buttons {
            .wp-block-button__link {
                --button-border: 3px solid var(--paletteColor8);
                &:hover {
                    --button-border-hover-color: var(--paletteColor8);
                }
            }
            .wp-block-button {
                &:nth-child(2) {
                    .wp-block-button__link {
                        --buttonInitialColor: var(--paletteColor2);
                        --button-border: 3px solid var(--paletteColor8);
                        &:hover {
                            --buttonHoverColor: #87a820;
                            --button-border-hover-color: var(--paletteColor8);
                        }
                    }
                }
            }
        }
    }
}

.gb-container-9bded24d.add-gradient {
        background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.75), transparent 75%), linear-gradient(180deg, rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url(/wp-content/uploads/2022/04/61bc9e9895b9fa4810496c2d_Saunawereld-Zutphen-293_edit.jpeg);
        background-position: 0px 0px, 0px 0px, 10% 50%;
        background-size: auto, auto, auto;
}