footer {
    .ct-container {
        --normal-container-max-width: 1200px;
    }

    .footer-social {
        display: flex;
        justify-content: center;
        position: relative;
        height: 24px;
        gap: 16px;
        margin: 1em 0 !important;

        svg {
            fill: var(--color);
        }
    }
}