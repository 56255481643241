@keyframes upAndDown {
    from {
        transform: translateY(-200%);
    }

    50% {
        transform: translateY(-150%);
    }

    to {
        transform: translateY(-200%);
    }
}

@keyframes bounceArrow {
    0% {
        transform: translateY(0)
    }
    25% {
        transform: translateY(24px)
    }
    75% {
        transform: translateY(16px)
    }
    100% {
        transform: translateY(0)
    }
}

@-webkit-keyframes spinX {  
    0%   {-webkit-transform: rotate(0deg);}  
    100% {-webkit-transform: rotate(360deg);}  
}

@keyframes spinX {  
    0%   {transform: rotate(0deg);}  
    100% {transform: rotate(360deg);}  
}