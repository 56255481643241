header {
    .ct-button {
        --buttonFontSize: 12px;
        --button-border: 3px solid var(--paletteColor8);
        &:hover {
            --button-border-hover-color: var(--paletteColor8);
        }
    }

    nav[class*="menu"] {
        li.has-image {
            > a {
                color: var(--paletteColor8);
                --icon-color: var(--paletteColor8);
            }
        }
    }
}

// Default for both desktop and mobile
#menu-main-menu, #menu-main-menu-1 {
    .sub-menu {
        .ct-menu-link {
            figure {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: -1;
                background-color: rgb(0, 0, 0);

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    opacity: .5;
                }
            }
        }
    }
}

// Changes for mobile only
#menu-main-menu-1 {
    .sub-menu {
        --has-indentation: false;

        li {
            a {
                padding-left: 16px;

                figure {
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: rgb(30,31,33);
                        background: linear-gradient(90deg, rgba(30,31,33,1) 0%, rgba(30,31,33,0) 100%);
                        z-index: 1;
                    }
                }
            }
        }
    }
}

.menu-all-products {

    @include break-below(sm) {
        display: none;
    }

}

.special-menu {
    flex-direction: column;
    text-align: center;
    gap: 8px;
    padding: 8px;

    a.button {
        color: #fff;
        --icon-color: #fff;
    }

    span {
        font-size: 13px;
        color: #fff;
    }
}

.menu-btn-group {
    display: flex;
    flex-direction: row;
    gap: 8px;

    .sauna-samenstellen {
        --button-border: 2px solid #fff;
        background-image: linear-gradient(180deg, rgba(52, 63, 82, 0.5), rgba(52, 63, 82, 0.5)), url(/wp-content/uploads/2022/05/menu-samenstellen.jpeg);
        background-size: auto, cover;
        background-repeat: no-repeat;
        background-position: 0px 0px, 50% 50%;
        flex: 1;
    }

    .sauna-op-maat {
        --button-border: 2px solid #fff;
        background-image: linear-gradient(180deg, rgba(52, 63, 82, 0.5), rgba(52, 63, 82, 0.5)), url(/wp-content/uploads/2022/05/61bb279d68dc6e4eec12610a_Saunawereld-Zutphen-396.jpeg);
        background-size: auto, cover;
        background-repeat: no-repeat;
        background-position: 0px 0px, 50% 50%;
        flex: 1;
    }
}