.wp-block-buttons {
    .wp-block-button {
        &:nth-child(2) {
            .wp-block-button__link {
                --buttonInitialColor: var(--paletteColor2);
                --button-border: 1px solid var(--paletteColor2);
                &:hover {
                    --buttonHoverColor: #87a820;
                    --button-border-hover-color: #87a820;
                }
            }
        }
    }
}

.wp-block-button.simple-button {
    --buttonTextInitialColor: var(--paletteColor4);
    --button-border: 1px solid var(--paletteColor5);
    --buttonInitialColor: var(--paletteColor8);
    --buttonFontSize: 12px;
}