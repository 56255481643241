.flex {
    &__jc-sb {
        justify-content: space-between;
    }
}

.bottom-shape {
    position: relative;
    
    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 125px;
        bottom: 0;
        left: 0;
        background-image: url(../images/shape-bottom.svg);
        background-repeat: no-repeat;
        background-size: 100% 125px;
        z-index: 2;

        @include break-below(sm) {
            height: 64px;
            background-size: 100% 64px;
        }
    }
}

@include break-above(sm) {
    .move {
        &-15 {
            transform: translateX(15%);
        }
        &-5 {
            transform: translateX(5%);
        }
    }
}

.hidden {
    @include break-above(lg) {
        &-desktop {
            display: none;
        }
    }

    @include break-between(sm, lg) {
        &-tablet {
            display: none;
        }
    }

    @include break-below(xs) {
        &-mobile {
            display: none;
        }
    }
}