body:not(.single) {
    #main .entry-content>* {
        margin-bottom: 0;
    }
}

.pulse-arrow {
    animation-duration: 1.8s;
    animation-name: upAndDown;
    animation-iteration-count: infinite;
}

.bounce-arrow {
    animation-duration: 1.5s;
    animation-name: bounceArrow;
    animation-iteration-count: infinite;
}

.margin-auto {
    margin-top: auto;
}

.product-cards {
    .gb-inside-container {
        height: 100%;

        .wp-block-media-text {
            height: 100%;

            .wp-block-media-text__content {
                height: 100%;
                display: flex;
                flex-direction: column;

                .product-card-bottom {
                    margin-top: auto;
                }
            }
        }
    }

    .wp-block-media-text.is-image-fill {
        figure {
            border-radius: 8px;
            overflow: hidden;
        }
    }

    .wp-block-media-text__content {
        padding: 0 1em;
    }
}

.crossed-images {

    figure {
        overflow: hidden;
        border-radius: 1.5em;
        box-shadow: 0 0 12px rgba(0, 0, 0, .5);

        &:nth-child(2) {
            margin: -64px 0 0 -64px;
        }

        &:nth-child(3) {
            margin: -128px 128px 0 0;
        }
    }

    @include break-below(xs) {
        > div {
            &:nth-child(2), &:nth-child(3) {
                display: none;
            }
        }
    }

}

.multi-column {
    --columns: 2;
    --column-gap: 24px;
    --listIndent: 1em;
    -moz-column-count: var(--columns);
    -moz-column-gap: var(--column-gap);
    -webkit-column-count: var(--columns);
    -webkit-column-gap: var(--column-gap);
    column-count: var(--columns);
    column-gap: var(--column-gap);

    @include break-below(sm) {
        --columns: 1;
        --columns-gap: 0;
    }
}

.openingstijden {
    display: grid;
    grid-template-columns: 35% 65%;
}


.sauna-list-item {
    .splide__slide {
        img {
            width: 100%;
        }
    }
    .splide__arrows {
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 50%;
        right: 10px;
        left: 10px;
        transform: translateY(-50%);
        z-index: 5;
        .splide__arrow {
            border: none;
            margin: 0;
            padding: 0;
            width: auto;
            overflow: visible;
            background: transparent;
            color: inherit;
            font: inherit;
            line-height: normal;
            appearance: none;
            cursor: pointer;
            opacity: 0.5;
            &:hover {
                opacity: 1;
            }
            svg {
                height: 30px;
                width: 30px;
                fill: #fff;
                filter: drop-shadow(2px 2px 4px rgba(0,0,0,.4));
            }
            &.splide__arrow--prev {
                svg {
                    transform: scaleX(-1);
                }
            }
            &.splide__arrow--next {
                
            }
        }
    }
}